<template>
  <div class="page-container">
    <header-tab activeIndex="2"></header-tab>
    <div
      class="p-t-header"
      v-lazy:background-image="{src: require('../../assets/images/lunbo3.png')}"
    >
      <div class="p-t-inner">
        <div align="center">
          <span class="home-carousel-s1">九医云医院</span>
          <span class="home-carousel-line"></span>
          <span class="home-carousel-s3">打造一站式互联网+医疗服务平台<br>
            重构医疗服务场景</span>
        </div>
      </div>
    </div>
    <application-scenario></application-scenario>
    <platform-advantage></platform-advantage>
    <multi-end></multi-end>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: "cloudHospital",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    "application-scenario": () => import("./components/ApplicationScenario"),
    "platform-advantage": () => import("./components/PlatformAdvantage"),
    "multi-end": () => import("./components/MultiEnd"),
    "nav-footer": () => import("@/components/navFooter"),
  },
};
</script>

<style lang="less" scoped>
.p-t-header {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.p-t-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>